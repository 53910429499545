<template>
  <ul class="page-list">
    <li class="active"><a href="#">1</a></li>
    <li><a href="#">2</a></li>
    <li><a href="#">3</a></li>
    <li><a href="#">4</a></li>
    <li>
      <a href="#"><i class="fas fa-angle-right"></i></a>
    </li>
  </ul>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>
